<template>
  <div>
    <div
      class="hero-image mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="hero-text">
        <h1>Enquire Now</h1>
        <h3>Kickstart your health</h3>
        <h3>
          <i>Change for Good</i>
        </h3>
      </div>
    </div>

    <featured-in></featured-in>

    <div class="body-content mx-auto">
      <!-- Form Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mb-4 mx-auto">
            <div class="text-center">
              Complete the enquiry form below and we’ll call you back.
            </div>
            <div class="text-center">
              <span style="color: red">*</span>&nbsp;Required info.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 mb-4 mx-auto">
            <enquiry-form
              :source="'web_form'"
              :traffic_campaign="traffic_campaign"
              :traffic_source="traffic_source"
            ></enquiry-form>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 mb-4 mx-auto">
            <div class="green-header-4-text text-center">
              We look forward to helping you plan your perfect health retreat.
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-8 mx-auto mb-3">
            <div class="row mb-2">
              <div class="col-md-6 mb-4 text-center">
                <div class="green-header-3-text text-center mb-3">
                  Amchara Somerset, UK
                </div>
                <img
                  width="221"
                  src="@/assets/images/logos/generic/trip-advisor-logo-with-rating.svg"
                  alt="TA logo"
                />
              </div>
              <div class="col-md-6 text-center">
                <div class="green-header-3-text text-center mb-3">
                  Amchara Gozo, Malta
                </div>
                <img
                  width="221"
                  src="@/assets/images/logos/generic/trip-advisor-logo-with-rating.svg"
                  alt="TA logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr width="80%" />
      <div class="mt-2 mb-2">&nbsp;</div>

      <featured-mags></featured-mags>
      <testimonials></testimonials>
    </div>
    <!-- body-content End -->

    <newsletter-cta></newsletter-cta>
  </div>
</template>

<script>
import FeaturedIn from "./page-partials/FeaturedIn";
import FeaturedMags from "./page-partials/FeaturedMags";
import Testimonials from "./page-partials/Testimonials";
import NewsletterCta from "./page-partials/NewsletterCta";
import EnquiryForm from "./page-partials/EnquiryForm";

export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      bgImages: [
        require("@/assets/images/pages/enquiries/header.jpg")
      ],
      traffic_campaign: sessionStorage.getItem('traffic_campaign'),
      traffic_source: sessionStorage.getItem('traffic_source'),
    };
  },
  components: {
    FeaturedIn,
    FeaturedMags,
    Testimonials,
    NewsletterCta,
    EnquiryForm,
  },
};
</script>

<style>
</style>